// extracted by mini-css-extract-plugin
export var articleContainer = "index-module--articleContainer--kNKI3";
export var articleImage = "index-module--articleImage--dkxYp";
export var canopyGreen = "index-module--canopyGreen--aaEsV";
export var carbonGrey = "index-module--carbonGrey--rbJck";
export var continueReadingContainer = "index-module--continueReadingContainer--bhWik";
export var copyContainer = "index-module--copyContainer--jQ5H+";
export var dawnPink = "index-module--dawnPink--6+UDh";
export var headlineContainer = "index-module--headlineContainer--dOPsg";
export var hide = "index-module--hide--WpHKx";
export var hiveYellow = "index-module--hiveYellow--FbNVx";
export var horizonWhite = "index-module--horizonWhite--vQiGC";
export var nextArticle = "index-module--nextArticle---LlKG";
export var nextArticleContent = "index-module--nextArticleContent--awhYn";
export var nextArticleImage = "index-module--nextArticleImage--GmYul";
export var nextArticles = "index-module--nextArticles--uDCWh";
export var oceanicBlue = "index-module--oceanicBlue--RB9Z4";
export var page404Title = "index-module--page404-title--NcRBb";
export var show = "index-module--show--0fHZ8";
export var simpleColouredCopyBlock = "index-module--simpleColouredCopyBlock--ErYov";
export var simpleRichTextArea = "index-module--simpleRichTextArea--b+C5N";
export var socialIconsContainer = "index-module--socialIconsContainer--RXtvc";
export var stickyHeadlineContainer = "index-module--stickyHeadlineContainer--Qpf5s";
export var stickySocialIconsContainer = "index-module--stickySocialIconsContainer--aC3Bq";
export var tagsContainer = "index-module--tagsContainer---hUQO";