import { graphql, navigate } from "gatsby";
import React from "react";
import Layout from "../../components/Layout";
import emailIcon from "../../images/icons/emailIconBlack.svg";
import twitterIcon from "../../images/icons/twitterIconBlack.svg";
import linkedInIcon from "../../images/icons/linkedInIconBlack.svg";
import rightArrowIcon from "../../images/icons/rightArrowIcon.svg";

import {
  articleContainer,
  headlineContainer,
  socialIconsContainer,
  articleImage,
  copyContainer,
  canopyGreen,
  oceanicBlue,
  hiveYellow,
  carbonGrey,
  dawnPink,
  simpleColouredCopyBlock,
  simpleRichTextArea,
  continueReadingContainer,
  nextArticle,
  nextArticleContent,
  nextArticles,
  nextArticleImage,
  show,
  hide,
  stickyHeadlineContainer,
  stickySocialIconsContainer,
} from "./index.module.scss";
import {
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import "intersection-observer";
import { useInView } from "react-intersection-observer";
import ScrollIndicator from "../../components/ScrollIndicator/ScrollIndicator";
import { useMedia } from "../../hooks/useMedia";
import Newsletter from "../../components/Newsletter/Newsletter";
import Moment from "react-moment";

const colorLookup = {
  canopyGreen: canopyGreen,
  oceanicBlue: oceanicBlue,
  hiveYellow: hiveYellow,
  carbonGrey: carbonGrey,
  dawnPink: dawnPink,
};

const EventsTemplate = ({
  data,
  location,
  data: {
    allArticles: { nodes: allArticles },
    article: {
      copy,
      publish_at,
      image,
      media_type,
      media_type: { tyoe: type },
      shortDesc,
      subtitle,
      slug,
      multiComponent,
      metaData,
    },
  },
}) => {
  const articleIndex = allArticles.findIndex((item) => item.slug === slug);

  const { href: url } = location;

  const nextTwoArticles = allArticles.slice(articleIndex + 1, articleIndex + 3);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const [scrollRef, scrollnView] = useInView({
    /* Optional options */
    threshold: 0,
  });

  const { mediaType } = useMedia(
    ["(min-width: 1440px)", "(min-width: 769px)", "(max-width: 768px)"],
    [
      {
        mediaType: "desktop",
      },
      {
        mediaType: "tablet",
      },

      { mediaType: "mobile" },
    ],
    // Default object
    { mediaType: "mobile" }
  );

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: image?.image?.localFile?.publicURL,
        article: true,
      }}
    >
      <div className={articleContainer}>
        <p className="ui">{type === "Article" ? "LATEST INSIGHTS" : type}</p>
        <div className={headlineContainer}>
          <h2>{shortDesc}</h2>
          <p className="ui">
            <Moment format="D MMM YYYY">{publish_at}</Moment>
          </p>
          {/* Temporarily commented out.  to be added later
          <div className={tagsContainer}>
            <p className="ui">retirement</p>
            <p className="ui">dws</p>
          </div> */}
          <div className={socialIconsContainer}>
            <p className="ui">SHARE</p>
            <TwitterShareButton url={url} quote={shortDesc}>
              <img src={twitterIcon} alt="twitter-icon" />
            </TwitterShareButton>
            <LinkedinShareButton url={url} title={shortDesc}>
              <img src={linkedInIcon} alt="linkedin-icon" />
            </LinkedinShareButton>
            <EmailShareButton url={url} subject={shortDesc}>
              <img src={emailIcon} alt="email-icon" />
            </EmailShareButton>
          </div>
        </div>
        <div className={articleImage} ref={scrollRef}>
          <GatsbyImage
            image={image?.image?.localFile?.childImageSharp?.gatsbyImageData}
            alt={image?.image?.alternativeText}
          />
        </div>

        {/* Sticky headline on scroll */}
        <div
          className={`${stickyHeadlineContainer} ${scrollnView ? hide : show}`}
        >
          {mediaType === "mobile" ? null : (
            <GatsbyImage
              image={image?.image?.localFile?.childImageSharp?.gatsbyImageData}
              alt={image?.image?.alternativeText}
            />
          )}
          {mediaType === "mobile" ? null : <p className={`ui`}>{shortDesc}</p>}
          <div className={stickySocialIconsContainer}>
            <p className="ui">SHARE</p>
            <LinkedinShareButton url={url} title={shortDesc}>
              <img src={linkedInIcon} alt="linkedin-icon" />
            </LinkedinShareButton>
            <EmailShareButton url={url} subject={shortDesc}>
              <img src={emailIcon} alt="email-icon" />
            </EmailShareButton>
            <TwitterShareButton url={url} quote={shortDesc}>
              <img src={twitterIcon} alt="twitter-icon" />
            </TwitterShareButton>
          </div>
          <ScrollIndicator />
        </div>

        <div className={copyContainer}>{copy ? parse(copy) : null}</div>
        {multiComponent.map((component) => {
          const { strapi_component, id } = component;
          switch (strapi_component) {
            case "simple.coloured-copy-block":
              const { copy, backgroundColor } = component;

              return (
                <div
                  key={id}
                  className={`${simpleColouredCopyBlock} ${colorLookup[backgroundColor]}`}
                >
                  {copy ? parse(copy) : null}
                </div>
              );

            case "simple.rich-text-area":
              const { text } = component;
              return (
                <div key={id} className={`${simpleRichTextArea}`}>
                  {text ? parse(text) : null}
                </div>
              );

            default:
              return null;
          }
        })}

        <div className={`${continueReadingContainer} ${inView ? show : hide}`}>
          <h4>Continue Reading</h4>
          <div className={nextArticles}>
            {nextTwoArticles.map((article, index) => (
              <div
                key={article.id}
                className={nextArticle}
                onClick={() => navigate(`/events/${article.slug}`)}
                role="button"
                tabIndex={index}
                onKeyDown={(event) => {
                  if (event.keycode === 13) navigate(`/events/${article.slug}`);
                }}
              >
                <GatsbyImage
                  image={
                    article?.image?.image?.localFile?.childImageSharp
                      ?.gatsbyImageData
                  }
                  alt={article?.image?.image?.alternativeText}
                  className={nextArticleImage}
                />
                <div className={nextArticleContent}>
                  <p className="ui">{article.shortDesc}</p>
                  <p className="ui">
                    <Moment format="D MMM YYYY">{publish_at}</Moment>
                  </p>
                </div>
                <img
                  src={rightArrowIcon}
                  alt="right-arrow-icon"
                  width="10"
                  heigth="10"
                />
              </div>
            ))}
          </div>
        </div>
        <Newsletter ref={ref} />
      </div>
    </Layout>
  );
};

export default EventsTemplate;

export const query = graphql`
  query GetEvent($slug: String) {
    article: strapiEvents(slug: { eq: $slug }) {
      multiComponent
      id
      published_at
      publish_at
      client_types {
        Type
      }
      copy
      image {
        longDesc
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1440)
            }
          }
        }
      }
      media_type {
        tyoe
      }
      shortDesc
      subtitle
      slug

      metaData {
        pageTitle
        metadescription
      }
    }
    allArticles: allStrapiEvents {
      nodes {
        id
        published_at
        shortDesc
        slug
        subtitle
        media_type {
          tyoe
        }
        image {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 80, width: 122)
              }
            }
          }
        }
      }
    }
  }
`;
